<template>
  <div class="content-wrapper">
    <page-header
      screen-name="Ver adesões nacionais"
      :link-items="linkItems"
    />

    <overlay
      :show="loading"
    >
      <div class="card p-2">
        <b-row
          v-if="canInsert"
          class="mb-2"
        >
          <b-col>
            <b-link
              type="button"
              class="btn custom-blue btn-add"
              :to="{ name: 'choose-campaign-nacional' }"
            >
              <feather-icon
                icon="PlusIcon"
              />
              Nova Adesão Nacional
            </b-link>
          </b-col>
        </b-row>

        <Filters
          :disable-button-search="table.tableBusy"
          :disable-button-clear="getDisableButtonClear || table.tableBusy"
          @actionSearch="handleSubmitFormFilters"
          @actionClear="clearFilters"
        >
          <validation-observer ref="formFilters">
            <b-form>
              <b-row class="align-items-center">
                <b-col
                  sm="6"
                  lg="4"
                >
                  <b-form-group
                    label="Nome da empresa"
                    label-for="companyName"
                    label-class="font_size_label"
                  >
                    <b-form-input
                      id="companyName"
                      v-model="search.companyName"
                      maxlength="100"
                      placeholder="Nome da empresa"
                      autocomplete="off"
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  sm="6"
                  lg="4"
                >
                  <b-form-group
                    label="CNPJ e CPF"
                    label-for="cpfCnpj"
                    label-class="font_size_label"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="CNPJ e CPF"
                      rules="cpfCnpj"
                    >
                      <the-mask
                        id="cpfCnpj"
                        ref="inputMask"
                        v-model="search.companyCpfCnpj"
                        class="form-control"
                        :mask="['###.###.###-##', '##.###.###/####-##']"
                        placeholder="00.000.000/0000-00"
                        autocomplete="off"
                      />

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col
                  sm="6"
                  lg="4"
                >
                  <b-form-group
                    label="Campanha nacional"
                    label-for="campaign"
                    label-class="font_size_label"
                  >
                    <v-select
                      id="campaign"
                      v-model="search.chooseCampaign"
                      :options="campaigns"
                      variant="custom"
                      item-text="descricao"
                      item-value="id_campanha"
                      placeholder="Selecione uma campanha nacional"
                      label="descricao"
                    >
                      <template
                        v-slot:no-options
                      >
                        Nenhum registro encontrado.
                      </template>
                    </v-select>
                  </b-form-group>
                </b-col>

                <b-col
                  sm="6"
                  lg="4"
                >
                  <b-form-group
                    label="Grupo de empresas"
                    label-for="group"
                    label-class="font_size_label"
                  >
                    <v-select
                      id="group"
                      v-model="search.chooseGroup"
                      :options="groups"
                      variant="custom"
                      item-text="descricao"
                      item-value="id_grupo"
                      placeholder="Selecione um grupo de empresas"
                      label="descricao"
                    >
                      <template
                        v-slot:no-options
                      >
                        Nenhum registro encontrado.
                      </template>
                    </v-select>
                  </b-form-group>
                </b-col>

                <b-col
                  sm="6"
                  lg="4"
                >
                  <b-form-group
                    label="Operador"
                    label-for="operator"
                    label-class="font_size_label"
                  >
                    <v-select
                      id="operator"
                      v-model="search.chooseOperatorGroup"
                      :options="departmentsOperatorGroups"
                      variant="custom"
                      item-text="descricao"
                      item-value="id_departamento"
                      placeholder="Selecione um operador"
                      label="descricao"
                    >
                      <template
                        v-slot:no-options
                      >
                        Nenhum registro encontrado.
                      </template>
                    </v-select>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </Filters>

        <b-row class="mb-2">
          <b-col
            class="px-3"
            sm="6"
          >
            <div
              v-if="showTable"
              class="d-flex justify-center-center align-items-center"
            >
              <span class="mr-50">Mostrar</span>
              <v-select
                id="orders"
                v-model="paginationData.defaultSize"
                :options="table.tableRows"
                :clearable="false"
                @input="updateQtdView($event)"
              >
                <span slot="no-options">Nenhuma opção selecionável.</span>
              </v-select>
            </div>
          </b-col>

          <b-col
            cols="12"
            class="text-center"
          >
            <p
              v-if="table.empty"
              class="table-empty mt-3"
            >
              Nenhum registro encontrado.
            </p>

            <p
              v-if="table.tableError"
              class="table-empty mt-3"
            >
              Sistema de busca indisponível no momento.
            </p>
          </b-col>

          <b-col
            v-if="loadingTable"
            cols="12"
          >
            <div
              v-if="table.tableBusy"
              class="spinner-area"
            >
              <b-spinner class="spinner-border text-custom-blue" />
            </div>
          </b-col>

          <b-col
            v-if="showTable"
            cols="12"
            class="my-2"
          >
            <b-table
              responsive
              sticky-header="380px"
              :busy.sync="table.tableBusy"
              :no-local-sorting="true"
              :fields="table.fields"
              :items="table.items"
              @context-changed="handleOrderTable"
            >
              <template #cell(descricao_grupo)="row">
                <span>{{ row.item.descricao_grupo }}</span>
              </template>
              <template #cell(descricao_campanha)="row">
                <span>{{ row.item.descricao_campanha }}</span>
              </template>
              <template #cell(sigla)="row">
                <span>{{ row.item.sigla }}</span>
              </template>
              <template #cell(actions)="row">
                <button-icon
                  v-b-tooltip.hover.html
                  title="<b>Detalhes Adesão Nacional</b>"
                  color="#2772C0"
                  size="18"
                  class-name="m-button-icon"
                  feather-icon="EyeIcon"
                  @action="redirectToView(row.item)"
                />
                <button-icon
                  v-if="canEdit"
                  v-b-tooltip.hover.html
                  title="<b>Editar Adesão Nacional</b>"
                  color="#2772C0"
                  size="18"
                  class-name="m-button-icon"
                  feather-icon="EditIcon"
                  @action="redirectToEdit(row.item)"
                />
                <button-icon
                  v-if="canDelete"
                  v-b-tooltip.hover.html
                  title="<b>Excluir Adesão Nacional</b>"
                  color="#2772C0"
                  size="18"
                  class-name="m-button-icon"
                  feather-icon="Trash2Icon"
                  @action="handleConfirmToRemove(row.item)"
                />
              </template>
            </b-table>
          </b-col>

          <b-col
            v-if="showTable"
            class="px-3"
            sm="12"
          >
            <CustomPagination
              :paginacao="paginationData"
              @page-cliked="updateCurrentPage"
            />
          </b-col>
        </b-row>
      </div>
    </overlay>

  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BTable,
  BSpinner, BLink, BFormInput,
} from 'bootstrap-vue'

import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required, cpfCnpj } from '@validations'

import vSelect from 'vue-select'
import { TheMask, mask } from 'vue-the-mask'
import CustomPagination from '@/views/components/custom/pagination/CustomPagination.vue'
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue'
import Overlay from '@/views/components/custom/overlay/Overlay.vue'
import {
  getCampaignsOperatorGroups,
  getNationalPurchaseGroups,
  getNationalCampaigns,
  removeNationalPurchase, getDepartmentsOperatorGroups,
} from '@/views/custom-pages/adesao-nacional/requests'
import { actions, subjects } from '@/libs/acl/rules'
import ButtonIcon from '@/views/components/custom/Buttons/ButtonIcon/ButtonIcon.vue'
import {
  confirmActionTriangleWarn,
  successCheckCircleMessage,
  warningMessage,
} from '@/libs/sweetalerts'
import Filters from '@/views/components/custom/filters/Filters.vue'

export default {
  title: 'Adesão Nacional',

  directives: {
    mask,
  },

  components: {
    BFormInput,
    Filters,
    ButtonIcon,
    BLink,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BTable,
    BSpinner,
    Overlay,
    vSelect,
    TheMask,
    PageHeader,
    CustomPagination,
    ValidationObserver,
    ValidationProvider,
  },

  data() {
    return {
      linkItems: [
        {
          name: 'Adesão Nacional',
          active: true,
          routeName: 'listar-adesao-nacional',
        },
      ],

      required,
      cpfCnpj,

      loading: true,
      loadingTable: false,

      titlePage: '',

      showTable: false,
      switchDisabled: false,

      disableGroupsSelect: true,
      disableOperatorGroupsSelect: true,

      paginationData: {
        currentPage: 0,
        totalLines: 0,
        fromLine: 0,
        toLine: 0,
        defaultSize: 10,
      },

      campaigns: [],
      groups: [],
      departmentsOperatorGroups: [],

      search: {
        companyName: '',
        companyCpfCnpj: '',
        chooseCampaign: null,
        chooseGroup: null,
        chooseOperatorGroup: null,
      },

      table: {
        empty: false,
        tableError: false,
        tableBusy: true,
        tableRows: [10, 25, 50, 100],
        tableOrder: '',
        tableColumnNameOrder: '',
        fields: [
          {
            key: 'descricao_grupo', label: 'GRUPO', sortable: true, class: 'text-left',
          },
          {
            key: 'descricao_campanha', label: 'CAMPANHA NACIONAL', sortable: true, class: 'text-left',
          },
          {
            key: 'sigla', label: 'OPERADOR', sortable: true, class: 'text-left',
          },
          {
            key: 'actions', label: 'AÇÕES', sortable: false, class: 'mw-150 text-center col-1',
          },
        ],
        items: [],
      },
    }
  },

  computed: {
    canInsert() {
      return this.$can(actions.INSERIR, subjects.TIPO_ATUACAO_ADESAO_BASE_NACIONAL_COORDENADOR)
          || this.$can(actions.INSERIR, subjects.TIPO_ATUACAO_ADESAO_BASE_NACIONAL_OPERADOR)
    },
    canEdit() {
      return this.$can(actions.ATUALIZAR, subjects.TIPO_ATUACAO_ADESAO_BASE_NACIONAL_COORDENADOR)
          || this.$can(actions.ATUALIZAR, subjects.TIPO_ATUACAO_ADESAO_BASE_NACIONAL_OPERADOR)
    },
    canDelete() {
      return this.$can(actions.EXCLUIR, subjects.TIPO_ATUACAO_ADESAO_BASE_NACIONAL_COORDENADOR)
          || this.$can(actions.EXCLUIR, subjects.TIPO_ATUACAO_ADESAO_BASE_NACIONAL_OPERADOR)
    },

    getDisableButtonClear() {
      return [
        this.search.companyName === '',
        this.search.companyCpfCnpj === '',
        this.search.chooseCampaign === null,
        this.search.chooseGroup === null,
        this.search.chooseOperatorGroup === null,
      ].every(field => field === true)
    },
  },

  mounted() {
    this.index()
  },

  methods: {
    async index() {
      await this.handleGetCampaigns()
      await this.handleGetGroups()
      await this.handleGetDepartmentsOperatorGroups()

      this.loadingTable = true

      this.findAll()
    },

    handleSubmitFormFilters() {
      this.paginationData.currentPage = 1

      this.$refs.formFilters.validate()
        .then(success => {
          if (success) {
            this.findAll()
          }
        })
    },

    findAll() {
      this.table.tableBusy = true

      this.table.tableError = false
      this.table.empty = false

      getCampaignsOperatorGroups(this.getParams())
        .then(response => {
          if (response.status === 200) {
            if (response.data.data.length > 0) {
              this.showTable = true
              this.table.items = response.data.data
              this.table.tableBusy = false
              this.handlePagination(response.data)
              return
            }

            this.table.empty = true
            this.showTable = false
            this.table.tableBusy = false
          }
        })
        .catch(() => {
          this.table.tableError = true
          this.table.tableBusy = false
          this.showTable = false
        })
    },

    async handleGetCampaigns() {
      this.loading = true

      await getNationalCampaigns().then(response => {
        this.campaigns = response.data
      })

      this.loading = false
    },

    async handleGetGroups() {
      this.loading = true

      await getNationalPurchaseGroups()
        .then(response => {
          this.groups = response.data
        })

      this.loading = false
    },

    async handleGetDepartmentsOperatorGroups() {
      this.loading = true

      await getDepartmentsOperatorGroups()
        .then(response => {
          this.departmentsOperatorGroups = response.data
        })

      this.loading = false
    },

    handleConfirmToRemove(item) {
      confirmActionTriangleWarn('Deseja excluir esta adesão nacional ?')
        .then(() => {
          this.handleRemoveNationalPurchase(item)
        })
    },

    async handleRemoveNationalPurchase(item) {
      this.loading = true

      const formData = {
        idCampanha: item.id_campanha,
        idGrupoOperador: item.id_grupo_operador,
      }

      await removeNationalPurchase(formData)
        .then(response => {
          if (response.status === 204) {
            successCheckCircleMessage('Registro excluído com sucesso!')
          }
        })
        .catch(error => {
          this.handleError(error.response)
        })

      this.findAll()

      this.loading = false
    },

    handleError(response) {
      const errors = response.status === 400 || response.status === 404

      if (errors) {
        warningMessage(
          response.data.errors,
        )

        return
      }

      warningMessage(
        'Não foi possível realizar a sua solicitação.<br /> Entre em contato com o suporte.',
      )
    },

    redirectToView(item) {
      this.$store.commit('adesaoNacionalViewState/setPurchaseView', item)

      this.$router.push({ name: 'view-adesao-nacional' })
    },

    redirectToEdit(item) {
      this.$store.commit('adesaoNacionalViewState/setPurchaseUpdate', item)

      this.$router.push({ name: 'edit-adesao-nacional' })
    },

    clearFilters() {
      this.search.companyName = ''
      this.search.companyCpfCnpj = ''
      this.search.chooseCampaign = null
      this.search.chooseGroup = null
      this.search.chooseOperatorGroup = null

      this.findAll()
    },

    handleOrderTable(context) {
      this.table.tableColumnNameOrder = context.sortBy
      this.table.tableOrder = context.sortDesc ? 'desc' : 'asc'

      this.findAll()
    },

    getParams() {
      return {
        colunaNome: this.table.tableColumnNameOrder,
        colunaOrdem: this.table.tableOrder,
        porPagina: this.paginationData.defaultSize,
        pagina: this.paginationData.currentPage,

        nomeEmpresa: this.search.companyName,
        cpfCnpjEmpresa: this.search.companyCpfCnpj,
        idCampanha: this.search.chooseCampaign ? this.search.chooseCampaign.id_campanha : null,
        idGrupo: this.search.chooseGroup ? this.search.chooseGroup.id_grupo : null,
        idDepartamento: this.search.chooseOperatorGroup ? this.search.chooseOperatorGroup.id_departamento : null,
      }
    },

    handlePagination(data) {
      this.paginationData.fromLine = data.from
      this.paginationData.toLine = data.to
      this.paginationData.totalLines = data.total
      this.paginationData.currentPage = data.current_page
    },

    updateQtdView(event) {
      if (!event) {
        this.paginationData.defaultSize = 10
      }

      this.paginationData.currentPage = 1
      this.findAll()
    },

    updateCurrentPage(page) {
      this.paginationData.currentPage = page
      this.findAll()
    },
  },
}
</script>

<style lang="scss" scoped>
.m-button-icon {
  margin-right: 5px;
}

.margin-box {
  width: 20px;
  height: 22px;
}

@media (max-width: 400px) {
  .btn-add {
    width: 100%;
  }
}
</style>
